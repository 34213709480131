import PaymentResponseType = OhhEnums.PaymentResponseType;

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { OhhEnums } from '../../../common/enumerations/ohh.enums';

@Component({
    selector: 'ohh-payment-response',
    templateUrl: './payment-response.component.html',
    styleUrl: './payment-response.component.less'
})
export class PaymentResponseComponent implements OnInit {

    protected paymentResponseType: PaymentResponseType;
    protected responseText: string;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private route: ActivatedRoute,
                private broadcastService: BroadcastService,
                private router: Router
    ) {}

    ngOnInit() {

        this.paymentResponseType = this.route.snapshot.data['responseType'];
        this.responseText = this.paymentResponseType == PaymentResponseType.Success ? 'CASE_SENSITIVE.PAYMENT_SUCCESS' : 'CASE_SENSITIVE.PAYMENT_CANCELLED';

        if (this.paymentResponseType == PaymentResponseType.Success) {
            this.broadcastService.providerStatusChanged.next(true);
            // setTimeout(() => this.router.navigate([`/providers/account`], { queryParams: { newProvider: true } }));
            setTimeout(() => this.router.navigate([`/providers/account`], { queryParams:  { preferred: true }}), 3000);
        } else {
            this.router.navigate([`/`]);
        }

    }
}
