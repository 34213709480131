import { BackgroundCheckApiService } from './background-check-api.service';
import { ClearChecksNewOrder } from './models/clearChecksNewOrder';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BackgroundCheckManagerService {

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private backgroundCheckApiService: BackgroundCheckApiService) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    createNewOrder(order: ClearChecksNewOrder): Observable<any> {
        return this.backgroundCheckApiService.createNewOrder(order);
    }

}
