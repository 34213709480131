<!--<div class="ol-page-container" style="border: 1px solid red;">-->

<!--</div>-->

<div id="ol-payments-container">
	<ohh-section-header [topPadding]="40" [bottomPadding]="60">
		<div class="ol-sh-heading">
			<span style="text-transform: uppercase;">Hello <strong>Preferred Provider!</strong></span>
		</div>
		<div class="ol-sh-sub-heading small-margin">
			<span>Subscription Payment</span>
		</div>
		<div class="ol-sh-body small-margin">
			Please fill out the payment information below to unlock your Preferred Provider Benefits.

<!--			<div style="margin-top: 20px; font-size: 16px; font-style: italic;">-->
<!--				NOTE: Payment processing is handled by Stripe, our 3rd party payment processing partner. Ohh Look does not have access to any of the-->
<!--				information provided in the form below.-->
<!--			</div>-->
		</div>
	</ohh-section-header>

	<ohh-full-vw-constrained-container [bottomPadding]="200">
		<div style="flex: 1; border-top: 1px solid lightgrey; border-bottomX: 1px solid lightgrey; padding: 50px 0;">
			<div id="card-element"></div>
		</div>

		<ohh-waiting-indicator *ngIf="isBusy" [text]="'PHRASE.LOADING_STRIPE' | translate"></ohh-waiting-indicator>
	</ohh-full-vw-constrained-container>

</div>
