
<div id="ol-bc-container">

	<ohh-full-vw-constrained-container [bottomPadding]="200">
		<div style="padding: 2rem;">
			<button (click)="onOrderCheck()">Do Check</button>
		</div>

		<button class="btn" id="open-widget-btn">
			Open the Applicant Form
		</button>
	</ohh-full-vw-constrained-container>

</div>
