import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMap, MapAdvancedMarker, MapMarker } from '@angular/google-maps';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { AppInitializerService } from './common/services/app-initializer.service';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './api/auth/auth.interceptor';
import { BackgroundCheckComponent } from './core/background-check/background-check/background-check.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BusinessProfileComponent } from './core/user-account/business-profile/business-profile.component';
import { ConfirmEmailComponent } from './core/confirm-email/confirm-email.component';
import { CustomerProfileComponent } from './core/user-account/customer-profile/customer-profile.component';
import { DatePipe } from '@angular/common';
import { EarlySignUpComponent } from './core/early-sign-up/early-sign-up.component';
import { EmailSentComponent } from './core/reset-password-email-sent/email-sent.component';
import { environment } from '../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './root-components/footer/footer.component';
import { HeaderComponent } from './root-components/header/header.component';
import { HomeComponent } from './root-components/home/home.component';
import { LogInComponent } from './core/log-in/log-in.component';
import { MembershipSelectorComponent } from './core/membership-selector/membership-selector.component';
import { MenuBarComponent } from './root-components/header/menu-bar/menu-bar.component';
import { NeighborhoodComponent } from './core/neighborhood/neighborhood.component';
import { PaymentComponent } from './core/payment/payment.component';
import { PaymentResponseComponent } from './core/payment/payment-response/payment-response.component';
import { PendingConfirmationComponent } from './core/pending-confirmation/pending-confirmation.component';
import { ProSignUpBasicComponent } from './core/pro-sign-up-basic/pro-sign-up-basic.component';
import { ProviderDetailComponent } from './core/provider-detail/provider-detail.component';
import { ProviderIconListCardComponent } from './core/provider-detail/provider-location-card/provider-icon-list-card.component';
import { ProviderSignUpComponent } from './core/pro-sign-up/provider-sign-up.component';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import { ServiceCategoriesComponent } from './core/service-categories/service-categories.component';
import { ServicesComponent } from './core/services/services.component';
import { SharedModule } from './shared/shared.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserAccountComponent } from './core/user-account/user-account.component';
import { UserRegistrationComponent } from './core/user-registration/user-registration.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', `${environment.i18nCacheBuster}.json`);
}

export function appInitializerFactory(provider: AppInitializerService) {
    return () => provider.load();
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        ServiceCategoriesComponent,
        EarlySignUpComponent,
        MenuBarComponent,
        ServicesComponent,
        HomeComponent,
        ProviderSignUpComponent,
        UserRegistrationComponent,
        NeighborhoodComponent,
        LogInComponent,
        UserAccountComponent,
        ProviderDetailComponent,
        ProviderIconListCardComponent,
        CustomerProfileComponent,
        BusinessProfileComponent,
        ConfirmEmailComponent,
        PendingConfirmationComponent,
        EmailSentComponent,
        ResetPasswordComponent,
        PaymentComponent,
        PaymentResponseComponent,
        ProSignUpBasicComponent,
        MembershipSelectorComponent,
        BackgroundCheckComponent
    ],
    imports: [
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        FontAwesomeModule,
        GoogleMap,
        MapMarker,
        FormsModule,
        ReactiveFormsModule,
        MapAdvancedMarker,
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [AppInitializerService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
