import { BackgroundCheckManagerService } from '../../../api/background_check/background-check-manager.service';
import { ClearChecksNewOrder } from '../../../api/background_check/models/clearChecksNewOrder';
import { Component } from '@angular/core';

declare const ClearChecksApplicantWidget: any;

@Component({
    selector: 'ohh-background-check',
    templateUrl: './background-check.component.html',
    styleUrl: './background-check.component.less'
})
export class BackgroundCheckComponent {

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private backgroundCheckManager: BackgroundCheckManagerService) {

    }

    // =========================================================================================================================================================
    //
    // =========================================================================================================================================================

    onOrderCheck() {
        const order = new ClearChecksNewOrder({
            applicant_emails: ['mark02@thegsdgrp.com']
        });

        this.backgroundCheckManager.createNewOrder(order)
            .subscribe({
                next: (order) => {
                    this.setupClearChecksForm(order?.applicants[0].report_key)
                },
                error: (error) => {
                    console.log(error);
                }
            })
    }

    private setupClearChecksForm(reportKey: string) {
        // Add event listener after the view has been initialized
        const button = document.getElementById('open-widget-btn');
        if (button) {
            button.addEventListener('click', (e) => this.openApplicantWidget(reportKey));
        }
    }

    openApplicantWidget(reportKey: string): void {
        // Use the global ClearChecksApplicantWidget object
        const applicantWidget = ClearChecksApplicantWidget.init({
            reportKey: reportKey,

            onSuccess: (data: any) => {
                console.log('Success:', data);
            },

            onClose: () => {
                console.log('Widget was closed');
            },

            onError: (error: any) => {
                console.error('An error occurred:', error);
            },
        });

        applicantWidget.open();
    }
}
