<div id="mem-options">
	<div *ngIf="!isProvider" class="mem-option free">
		<div class="mem-option-body">
			<div class="mem-opt-title prevent-select">
				Free Membership
			</div>
			<div class="mem-opt-body prevent-select">
				<table>
					<tr class="ol-mem-value"><td><fa-icon icon="check"></fa-icon></td><td><div class="bene-text">Members Only</div></td></tr>
					<tr class="ol-mem-value"><td><fa-icon icon="check"></fa-icon></td><td><div class="bene-text">Increase Exposure to Your Local Community</div></td></tr>
					<tr class="ol-mem-value"><td><fa-icon icon="check"></fa-icon></td><td><div class="bene-text">Use Your Ad-Profile To Showcase Your Business</div></td></tr>
					<tr class="ol-mem-value"><td><fa-icon icon="check"></fa-icon></td><td><div class="bene-text">Get Listed In The Category Of Your Choice</div></td></tr>
				</table>
			</div>
		</div>
		<div class="mem-pln-btn ol-btn ol-std-btn" (click)="onFreeSelected()">
			<div class="prevent-select">Get This Plan</div>
			<div class="prevent-select"><em>Free</em></div>
		</div>
	</div>


	<div class="mem-option preferred">
		<div class="mem-option-body">
			<div class="mem-opt-title prevent-select">
				Preferred Membership
			</div>
			<div class="mem-opt-body prevent-select">
				<table>
					<tr class="ol-mem-value"><td><fa-icon icon="check"></fa-icon></td><td><div class="bene-text">Enjoy Free Membership Benefits +</div></td></tr>
					<tr class="ol-mem-value"><td><fa-icon icon="check"></fa-icon></td><td><div class="bene-text">Preferred Placement</div></td></tr>
					<tr class="ol-mem-value"><td><fa-icon icon="check"></fa-icon></td><td><div class="bene-text">Verified Badge With Background Check</div></td></tr>
					<tr class="ol-mem-value"><td><fa-icon icon="check"></fa-icon></td><td><div class="bene-text">Social Media Collaborations</div></td></tr>
					<tr class="ol-mem-value"><td><fa-icon icon="check"></fa-icon></td><td><div class="bene-text">Newsletter Shout-outs</div></td></tr>
					<tr class="ol-mem-value"><td><fa-icon icon="check"></fa-icon></td><td><div class="bene-text">Access To Classified Listings</div></td></tr>
<!--					<tr class="ol-mem-value"><td><fa-icon icon="check"></fa-icon></td><td><div class="bene-text">Get listed in multiple categories</div></td></tr>-->
				</table>
			</div>
		</div>
		<div class="mem-pln-btn ol-btn ol-std-btn preferred" (click)="onPreferredSelected()">
			<div class="prevent-select">Get This Plan</div>
			<div class="prevent-select"><em>$29/yr</em></div>
		</div>
	</div>

</div>
