import { ApiService, HttpMethod } from '../_common/api.service';
import { ClearChecksNewOrder } from './models/clearChecksNewOrder';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BackgroundCheckApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    createNewOrder(order: ClearChecksNewOrder): Observable<any> {
        return this.apiService.apiRequest<any>(BackgroundCheckApiService.createNewOrderUrl, HttpMethod.Post, order)
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}backgroundCheck`; }

    private static get createNewOrderUrl(): string { return `${BackgroundCheckApiService.baseUrl}/newOrder`; }

}
