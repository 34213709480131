export class ClearChecksNewOrder {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    report_sku: string = 'HIRE1';
    order_quantity: number = 1;
    applicant_emails: string[] = [];
    drug_test: string = 'N';
    drug_sku: string = "drug";
    mvr: string = 'N';
    employment: string = 'N';
    education: string = 'N';
    blj: string = 'N';
    federal_criminal: string = 'N';
    terms_agree: string = 'Y';
}
