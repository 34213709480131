import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ohh-membership-selector',
    templateUrl: './membership-selector.component.html',
    styleUrl: './membership-selector.component.less'
})
export class MembershipSelectorComponent {
    @Output() freePlanSelected = new EventEmitter();
    @Output() preferredPlanSelected = new EventEmitter();

    @Input() isProvider = false;

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onFreeSelected() {
        this.freePlanSelected.emit();
    }

    onPreferredSelected() {
        this.preferredPlanSelected.emit();
    }
}
