import { Injectable } from '@angular/core';
import { ApiService, HttpMethod } from '../_common/api.service';
import { OhhProvider } from '../provider/models/ohh-provider.model';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of, switchMap, take } from 'rxjs';
import { OhhCity } from './models/ohh-city.model';

@Injectable({
  providedIn: 'root'
})
export class CityApiService {
  private tempDataUrlBase = '../../assets/data';

  private apiService: ApiService;

  private citiesCache: OhhCity[] = [];

  // =========================================================================================================================================================
  // Ctor
  // =========================================================================================================================================================

  constructor(http: HttpClient) {
    this.apiService = new ApiService(http);
  }

  // =========================================================================================================================================================
  // Public Methods
  // =========================================================================================================================================================

  getCities(bypassCache = false): Observable<OhhCity[]> {
    if (!bypassCache && this.citiesCache != null && this.citiesCache.length > 0) {
      return of(this.citiesCache);
    }

    // TODO: TEMPORARY
    const dataUrl = `${this.tempDataUrlBase}/cities.json`;

    return this.apiService.apiRequest<any>(dataUrl, HttpMethod.Get)
        .pipe(map((result: OhhCity[]) => result.map(c => new OhhCity(c))));
  }

  // getProvider(providerId: number, byPassCache = false): Observable<OhhProvider> {
  //   return this.getProviders(byPassCache)
  //       .pipe(
  //           take(1),
  //           switchMap((providers: OhhProvider[]) => of(providers.find(p => p.id == providerId)))
  //       );
  // }

  // =========================================================================================================================================================
  // URLs
  // =========================================================================================================================================================

  // private static get baseUrl(): string { return `${ApiService.baseApiUrl}siteData`; }
  //
  // private static get updateDataRefreshIntervalUrl(): string { return `${SpatialAdjustApiService.baseUrl}/updateInterval`; }
}
